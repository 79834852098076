import "./assets/styles/styles.scss";

// Variables globales
let compteur = 0; // Compteur qui permet de connaître l'image sur laquelle on se trouve
let timer, elements, slides, slideWidth, speed, transition;

window.onload = () => {
    const load = document.querySelector(".loader-container");
    load.classList.add("stop");
    // On récupère le diaporama
    const diapo = document.querySelector(".diapo");
    // On récupère le data-speed
    speed = diapo.dataset.speed;
    transition = diapo.dataset.transition;

    elements = document.querySelector(".elements");

    // On clone la 1ère image
    let firstImage = elements.firstElementChild.cloneNode(true);

    // On injecte le clone à la fin du diapo
    elements.appendChild(firstImage);

    slides = Array.from(elements.children);

    // On récupère la largeur d'une slide
    slideWidth = diapo.getBoundingClientRect().width;

    // On récupère les flèches
    //let next = document.querySelector("#nav-droite");
    //let prev = document.querySelector("#nav-gauche");

    // On gère le clic
    //next.addEventListener("click", slideNext);
    //prev.addEventListener("click", slidePrev);

    // On automatise le défilement
    timer = setInterval(slideNext, speed);

    // On gère l'arrêt et la reprise
    //diapo.addEventListener("mouseover", stopTimer);
    //diapo.addEventListener("mouseout", startTimer);
}

/**
 * Cette fonction fait défiler le diaporama vers la droite
 */
function slideNext(){
    // On incrémente le compteur
    compteur++;
    elements.style.transition = transition+"ms linear";

    let decal = -slideWidth * compteur;
    elements.style.transform = `translateX(${decal}px)`;

    // On attend la fin de la transition et on "rembobine" de façon cachée
    setTimeout(function(){
        if(compteur >= slides.length - 1){
            compteur = 0;
            elements.style.transition = "unset";
            elements.style.transform = "translateX(0)";
        }
    }, transition);
}

/**
 * Cette fonction fait défiler le diaporama vers la gauche
 */
function slidePrev(){
    // On décrémente le compteur
    compteur--;
    elements.style.transition = transition+"ms linear";

    if(compteur < 0){
        compteur = slides.length - 1;
        let decal = -slideWidth * compteur;
        elements.style.transition = "unset";
        elements.style.transform = `translateX(${decal}px)`;
        setTimeout(slidePrev, 1);
    }

    let decal = -slideWidth * compteur;
    elements.style.transform = `translateX(${decal}px)`;
    
}

function stopTimer(){
    clearInterval(timer);
}

function startTimer(){
    timer = setInterval(slideNext, speed);

}


const mail = {

    form : document.querySelector('#form_message'),
    champ: document.querySelector('.form_opa'),
    message : document.querySelector('.message'),
    httpHeaders: new Headers(),
    

    apiUrl: "https://messenger.pecheetangmorthemer.fr/api/",
   

    uri : "sendmail",

    init: function() {
        const submit = document.querySelector('form');
        submit.addEventListener('submit', mail.controlMail);
        mail.httpHeaders.append("Content-Type", "application/json");
    },

    controlMail: function(event)
    {
        event.preventDefault();
      
        const name = document.querySelector('#message_name').value;
        const email = document.querySelector('#message_email').value;
        const message = document.querySelector('#message_content').value;
        
        if (!name || !email || !message ) 
        {
            const contentMessage = 'Veuillez renseigner tous les champs'
            mail.erreurMessage(contentMessage);
            
        } else {
            mail.wait();
            mail.sendMail(name,email,message);
        }
 
        
        
    },


    sendMail: function(name,email,message,token)
    {
        const data = {
            "name" : name,
            "email" : email,
            "content" : message
            //"checkbox" : checkbox
        }

        const config = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: mail.httpHeaders,
            body: JSON.stringify(data)
        }

        const fullUrl = mail.apiUrl + mail.uri;
        fetch(fullUrl, config)
        .then(function(response){
    
            return  response.json();
        })
        .then(
            function(message)
            {
               mail.clearSuccessMessage();
               if (message.success)
               {
                    mail.successMessage(message.success);
                    mail.cleanForm();
               } else {
                   mail.erreurMessage(message.errors);
               }
            });


        
    },

    erreurMessage: function(content) {

            mail.message.textContent = content;
        
       
        mail.champ.classList.add('start-message');
        mail.message.classList.add('alert-error');

        setTimeout(mail.clearErrorMessage, 2000);
    },
    wait: function() {
        mail.message.textContent = 'Veuillez patienter';
        mail.champ.classList.add('start-message');
        mail.message.classList.add('alert-success');

        setTimeout(mail.clearSuccessMessage, 5000);
    },

    successMessage: function(content) {
        mail.message.textContent = content + ' nous vous invitons à vérifier vos spams';
        mail.champ.classList.add('start-message');
        mail.message.classList.add('alert-success');

        setTimeout(mail.clearSuccessMessage, 5000);
    },

    clearErrorMessage: function() {
        mail.champ.classList.remove('start-message');
        mail.message.classList.remove('alert-error');

       
    },

    
    clearSuccessMessage: function() {
        mail.champ.classList.remove('start-message');
        mail.message.classList.remove('alert-success');

        
    },


    cleanForm: function()
    {
        const form = document.querySelector('form');
        form.reset();
    }

}

mail.init();